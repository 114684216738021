@import 'styles/includes';

.TextModule {
    $root: &;

    padding: 8rem 0;
    background-color: $black;
    color: $white;

    &--Light {
        margin: 8rem 0;
        padding: 0;
        background-color: $white;
        color: $black;
    }

    &__Container {
        @extend %container;
    }

    &__Grid {
        @include media(m) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 3rem;
        }

        #{$root}--FullHeading & {
            @include media(m) {
                grid-template-columns: size(9);
                justify-content: flex-end;
            }

            @include media(l) {
                grid-template-columns: size(6);
            }
        }
    }

    &__Title {
        margin: 0 0 2rem;
        max-width: 101rem;
        font-family: $font-family-bold;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 100%;

        @include media(m) {
            margin: 0 0 5rem;
            font-size: 7.2rem;
        }
    }

    &__Content {
        #{$root}--LargeHeading & {
            margin-left: auto;
        }
    }

    &__Richtext {
        @extend %richtext;

        a {
            &[href$='.doc'],
            &[href$='.docx'],
            &[href$='.pdf'] {
                &:after {
                    background-image: url('/img/icon-doc-white.svg');

                    #{$root}--Light & {
                        background-image: url('/img/icon-doc.svg');
                    }
                }
            }

            &[href^='https'] {
                &:after {
                    background-image: url('/img/icon-external-white.svg');

                    #{$root}--Light & {
                        background-image: url('/img/icon-external.svg');
                    }
                }
            }
        }

        p {
            &:has(i) {
                margin: 3rem 0 0;
                font-family: $font-family-regular;
                font-size: 1.2rem;
                font-weight: $font-weight-regular;
                font-style: italic;
                line-height: 130%;
            }
        }
    }

    &__Buttons {
        margin: 4rem 0 0;
        display: grid;
        gap: 1rem;
    }
}
